import useFetch from "hooks/useFetch";
import React from "react";

export default function RepositoryTable() {
  const { data, error, loading } = useFetch(
    "https://automate.silverlining.cloud/api/repository/fetch/all"
  );

  return (
    <table>
      <thead>
        <tr>
          <th>Repository Name</th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.githubRepositories.map((element) => (
            <tr>
              <td>{element.full_name}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}
