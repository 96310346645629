import React, { useEffect, useState } from 'react'
import NavigationBar from '../../components/Dashboard/NavigationBar';

import './../../styles/Dashboard/navigation.css'
import DashboardContent from 'components/Dashboard/DashboardContent/DashboardContent';
import DashboardWrapper from 'components/Dashboard/DashboardWrapper';

function Dashboard() {

  // TODO: Suggestion for fixing the CSS bug that we have 70px additional height.
  // TODO: Suggestion for fixing the NavBar bug when resizing the window. 
  return(
    <DashboardWrapper>
      <DashboardContent />
    </DashboardWrapper>
  )
}

export default Dashboard;