import { deleteSession, resendVerificationEmail } from "utils";
import {ReactComponent as MailIcon} from "../../assets/mail.svg";
import { ReactComponent as PowerIcon } from "feather-icons/dist/icons/power.svg";
import { useNavigate } from "react-router-dom";

export default function ConfirmationBox(props) {
    const navigate = useNavigate();
    
    const logOut = async () => {
        // TODO: Check our the issue regarding log out
        await deleteSession();
        window.location.href = "/"
    }

    const createResendMessage = () => {
        let element = document.createElement("span");
        element.innerText = "Confirmation email has been resent! Please check your inbox.";

        return element;
    }

    const resendEmail = async (e) => {
        let clickedElement = e.target;

        document.getElementsByClassName("load")[0].classList.toggle("invisible");

        if (clickedElement.classList.contains("resend-button")) {
            const response = await resendVerificationEmail();

            if (response.message === "Success") {
                clickedElement.classList.add("reveal-animation")
                clickedElement.innerText = ""
                clickedElement.appendChild(createResendMessage());
                clickedElement.classList.remove("resend-button");
                clickedElement.classList.add("resend-message")
                

                setTimeout(() => {
                    clickedElement.removeChild(clickedElement.firstChild)
                    clickedElement.classList.remove("reveal-animation")
                    clickedElement.classList.add("reveal-animation")
                    clickedElement.innerText = "Did not receive email or link expired?";
                    clickedElement.classList.add("resend-button");
                    clickedElement.classList.remove("resend-message")
                }, 10000);
            }
        }
    }


    
    return (
    <div className='page-container'>
        <div className='message-box'>
            <div className="logo-container">
                {props.logo === "mail" ?
                 <div style={{padding: "20px"}}><MailIcon /></div> : 
                 props.icon
                }
            </div>
            <h1> {props.title} </h1>
            <p lang="en">{props.message}</p>
            {props.hasButton ? <button className="dashboard-button" onClick={() => {navigate(props.buttonPath)}}>{props.buttonText}</button> : null}
            {props.hasResend ? <><hr /> <div className="resend-span resend-button" onClick={(e) => resendEmail(e)}><div className="load invisible"></div>Did not receive email or link expired?</div></> : null}
        </div>
        {props.hasSignOut ? <button className="signout-button" onClick={logOut}> <span className="power-icon"><PowerIcon /></span><span className="signout-text">Sign Out</span></button> : null } 
    </div>
  )
}