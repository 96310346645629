import { useOutletContext } from 'react-router-dom';

import ConfirmationBox from 'components/Dashboard/ConfirmationBox';

import { faCircleCheck } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../../styles/Dashboard/confirmation-box.css"


export default function AccountActivated() {
  const userContext = useOutletContext();
  
  const title = "Account Successfully Activated";
  const buttonText = "Go To Dashboard"
  const message = "Your account with the email " + userContext.hk + " has been activated. Click the button below to get started!" 

  return (
      <ConfirmationBox
        title={title}
        message={message}
        hasButton={true}
        buttonText={buttonText}
        buttonPath={"/app/dashboard"}
        logo="check"
        icon={<FontAwesomeIcon icon={faCircleCheck} size="10x" style={{color: "00c04b"}}/>}
      />
  )
}
