import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons"

import "./../../../styles/Auth/signupanimation.css";


export default function PasswordStrengthChecker(props) {
    const errorStyle = {color: "red"}
    const successStyle = {color: "green"}

    const [passwordStregth, setPasswordStrength] = useState({hasAtLeastEight: false, hasUpperCase: false, hasLowerCase: false, hasNumber: false});

    useEffect(() => {
        let newPasswordStregth = {};

        newPasswordStregth.hasAtLeastEight = hasEightOrMore(props.password);
        newPasswordStregth.hasUpperCase = containsUppercase(props.password);
        newPasswordStregth.hasLowerCase = containsLowercase(props.password);
        newPasswordStregth.hasNumber = hasNumber(props.password);

        setPasswordStrength({...newPasswordStregth});

    }, [props.password]);



    const hasEightOrMore = (str) => {
        return str.length >= 8;
    }

    const hasNumber = (str) => {
        return /\d/.test(str);
    }
    
    const containsUppercase = (str) => {
        return /[A-Z]/.test(str);
    }

    const containsLowercase = (str) => {
        return /[a-z]/.test(str);
    }


    return (
    <div className={props.password === "" ? "hide-pass-strength" : "pass-strength-checker-box"}>
        <div className="pass-strength-title">Password Requirments</div>
        <p style={passwordStregth.hasAtLeastEight ? successStyle : errorStyle}><FontAwesomeIcon icon={passwordStregth.hasAtLeastEight ? faCheck : faXmark} /> At least 8 characters</p>
        <p style={passwordStregth.hasUpperCase ? successStyle : errorStyle}><FontAwesomeIcon icon={passwordStregth.hasUpperCase ? faCheck : faXmark} /> At least one capital letter</p>
        <p style={passwordStregth.hasLowerCase ? successStyle : errorStyle}><FontAwesomeIcon icon={passwordStregth.hasLowerCase ? faCheck : faXmark} /> At least one lower case letter</p>
        <p style={passwordStregth.hasNumber ? successStyle : errorStyle}><FontAwesomeIcon icon={passwordStregth.hasNumber ? faCheck : faXmark} /> At least one number</p>
    </div>
  )
}
