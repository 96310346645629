import { useContext, useState, createContext } from "react";

const AuthenticationContext = createContext();
const AuthenticationUpdateContext = createContext();

export function useAuthenticationContext() {
  return useContext(AuthenticationContext);
}

export function useAuthenticationUpdateContext() {
  return useContext(AuthenticationUpdateContext);
}

export default function AuthProvider({ children }) {
  const [authenticationState, _setAuthenticationState] = useState(
    localStorage.getItem("loginStatus")
  );

  const setAuthenticationState = (value) => {
    localStorage.setItem("loginStatus", value);
    _setAuthenticationState(value);
  };

  return (
    <AuthenticationContext.Provider value={authenticationState}>
      <AuthenticationUpdateContext.Provider value={setAuthenticationState}>
        {children}
      </AuthenticationUpdateContext.Provider>
    </AuthenticationContext.Provider>
  );
}
