import React, { useEffect } from 'react'

export default function Loader() {

  // TODO: Fix the loader bug.
  useEffect(() => {
    let timer = setInterval(() => {
      if (document.readyState === "complete") {
        document.getElementsByClassName("loader-bg")[0].style.display = "none";
        clearInterval(timer);
      }
    }, 500);
  }, [])
  
  return (
    <div class="loader-bg">
        <div class="loader-track">
            <div class="loader-fill"></div>
        </div>
    </div>
  )
}