import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { saveBitBucketToken, saveGitHubToken } from "utils";

export default function TokenSave() {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    async function saveToken() {
      if (searchParams.get("type") === "github") {
        await saveGitHubToken(
          searchParams.get("access_token"),
          searchParams.get("refresh_token")
        );
      } else {
        await saveBitBucketToken(
          searchParams.get("access_token"),
          searchParams.get("refresh_token")
        );
      }
    }
    console.log("I reach here");
    saveToken();
  }, []);

  return null;
}
