import React, { useRef, useState } from "react";
import AnimationRevealPage from "./../../utils/AnimationRevealPage.js";
import { Container as ContainerBase } from "./../../components/TReact/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "./../../assets/signup-illustration.svg";
import logo from "./../../assets/logo.svg";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";

import { faGoogle, faGithub, faBitbucket } from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { userRegister } from "utils/api.js";

import { useAuthenticationUpdateContext } from "contexts/AuthenticationContext";

import GlobalStyles from "GlobalStyles.js";

import "./../../styles/Auth/social-login.css";
import PasswordStrengthChecker from "components/TReact/passwordstrength/PasswordStrengthChecker.js";

const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-2 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
`;

function SignUp() {
  const [formMessage, setFormMessage] = useState(null);
  const [formColor, setFormColor] = useState("#e31c1c");
  const [userPassword, setUserPassword] = useState("");

  const errorColor = "#d90d0d";
  const successColor = "#12a112";

  const logoLinkUrl = "#";
  const illustrationImageSrc = illustration;
  const headingText = "Sign Up to Silverlining";
  const socialButtons = [
    {
      icon: faGoogle,
      text: "Sign Up With Google",
      url: "api/auth/google",
      cssClass: "btn-google btn-social btn-lg btn-authentication-page",
    },
    {
      icon: faGithub,
      text: "Sign Up With GitHub",
      url: "api/auth/github",
      cssClass: "btn-github btn-social btn-lg btn-authentication-page",
    },
    {
      icon: faBitbucket,
      text: "Sign Up With BitBucket",
      url: "api/auth/bitbucket",
      cssClass: "btn-bitbucket btn-social btn-lg btn-authentication-page",
    },
  ];
  const submitButtonText = "Sign Up";
  const SubmitButtonIcon = SignUpIcon;
  const tosUrl = "#";
  const privacyPolicyUrl = "#";
  const signInUrl = "login";
  const setAuthenticationState = useAuthenticationUpdateContext();

  const userEmail = useRef();

  const registerUser = async () => {
    if (!userEmail.current.value) {
      setFormMessage("Email is required");
      return;
    } else if (!userPassword || userPassword === "") {
      setFormMessage("Password is required");
      return;
    } else {
      setFormMessage(null);
    }

    try {
      const response = await userRegister(userEmail.current.value, userPassword);
      if (response.message !== "Successful Authentication") {
        var errorMessage = response.match(new RegExp("Error: " + "(.*)" + "END"));
        errorMessage = errorMessage[1].replace("&quot;", " ");
        errorMessage = errorMessage.replace("&quot;", " ");
        setFormColor(errorColor);
        setFormMessage(errorMessage);
      } else {
        setFormColor(successColor);
        setFormMessage("Sign Up Successful. Redirecting...");

        setAuthenticationState("inactive");

        setTimeout(() => {
          document.location.href = "https://automate.silverlining.cloud/confirm-email";
        }, 2400);
      }
    } catch (error) {
      setFormColor(errorColor);
      setFormMessage("Something unknown went wrong. Please try again!");
    }
  };

  const updatePassword = (e) => {
    setUserPassword(e.target.value);
  };

  return (
    <AnimationRevealPage>
      <GlobalStyles />
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading>{headingText}</Heading>
              <FormContainer>
                <SocialButtonsContainer>
                  {socialButtons.map((socialButton, index) => (
                    <a
                      className={socialButton.cssClass}
                      key={index}
                      href={socialButton.url}
                    >
                      <FontAwesomeIcon icon={socialButton.icon} />
                      {socialButton.text}
                    </a>
                  ))}
                </SocialButtonsContainer>
                <DividerTextContainer>
                  <DividerText>Or Sign up with your e-mail</DividerText>
                </DividerTextContainer>
                <Form onSubmit={(e) => e.preventDefault()}>
                  <Input placeholder="Email" ref={userEmail} />
                  <Input
                    type="password"
                    placeholder="Password"
                    onInput={(e) => updatePassword(e)}
                  />
                  <PasswordStrengthChecker password={userPassword} />
                  <div
                    style={{
                      position: "relative",
                      top: "3px",
                      left: "2px",
                      color: formColor,
                      fontWeight: "bolder",
                    }}
                  >
                    {formMessage}
                  </div>
                  <SubmitButton type="submit" onClick={registerUser}>
                    <SubmitButtonIcon className="icon" />
                    <span className="text">{submitButtonText}</span>
                  </SubmitButton>
                  <p tw="mt-6 text-xs text-gray-600 text-center">
                    I agree to abide by Silverlining's{" "}
                    <a href={tosUrl} tw="border-b border-gray-500 border-dotted">
                      Terms of Service
                    </a>{" "}
                    and its{" "}
                    <a
                      href={privacyPolicyUrl}
                      tw="border-b border-gray-500 border-dotted"
                    >
                      Privacy Policy
                    </a>
                  </p>

                  <p tw="mt-8 text-sm text-gray-600 text-center">
                    Already have an account?{" "}
                    <a href={signInUrl} tw="border-b border-gray-500 border-dotted">
                      Sign In
                    </a>
                  </p>
                </Form>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
}

export default SignUp;
