import ConfirmationBox from 'components/Dashboard/ConfirmationBox';

import { useOutletContext } from 'react-router-dom';

import "../../styles/Dashboard/confirmation-box.css"

export default function ConfirmEmail() {
    const userContext = useOutletContext();

    const title = "Verify Your Email";
    const message = "A confirmation email has been sent to " + userContext.email + ". Please check your inbox to verify the email in order to get started!"
    
    return (
        <ConfirmationBox
          title={title}
          message={message}
          hasButton={false}
          hasSignOut={true}
          hasResend={true}
          logo="mail"
        />
    )
}
