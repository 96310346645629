import NavigationBar from "components/Dashboard/NavigationBar";
import PaymentStatusPopover from "components/Dashboard/PaymentStatusPopover";
import React, { useEffect, useState } from "react";
import { createCheckoutLink } from "../../utils";

function Billing() {
  const [addedBalance, setAddedBalance] = useState(0.0);
  const [inputError, setInputError] = useState(["", false]);

  useEffect(() => {}, []);

  useEffect(() => {
    if (inputError[1] && addedBalance > 10) {
      setInputError(["", false]);
    }
  }, [addedBalance]);

  async function handleCheckout() {
    if (addedBalance < 10) {
      setInputError(["The minimum top up value is 10$", true]);
      return;
    }

    const response = await createCheckoutLink(addedBalance);
    if (response.url) {
      window.location.href = response.url;
    }
  }

  const updatePrice = (e) => {
    var t = e.target.value;
    const roundedValue =
      t.indexOf(".") >= 0 ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3) : t;
    e.target.value = roundedValue;
    setAddedBalance(roundedValue);
  };

  return (
    <div class="pcoded-main-container">
      <PaymentStatusPopover />
      <div class="pcoded-wrapper">
        <div class="pcoded-content">
          <div class="pcoded-inner-content">
            {/* <!-- [ breadcrumb ] start -->

                    <!-- [ breadcrumb ] end --> */}
            <div class="main-body">
              <div class="page-wrapper">
                {/* <!-- [ Main Content ] start --> */}
                <div class="row">
                  <div>
                    <div>
                      <div>
                        <h2>
                          Your Remaining Balance is ${" "}
                          {/* {(userContext.remainingTime * 0.001).toFixed(2)} */}
                        </h2>
                        <p>
                          {/* equivalent to {userContext.remainingTime} seconds of runtime */}
                        </p>
                      </div>

                      <h2>Add more execution time</h2>

                      <div>
                        <div>
                          <label>Enter the top up amount</label>
                          <br />
                          <input
                            onInput={(event) => updatePrice(event)}
                            step="0.1"
                            type="number"
                          ></input>
                          <br />
                          {inputError[1] ? (
                            <>
                              <label style={{ color: "red" }}>{inputError}</label>
                              <br />
                            </>
                          ) : null}
                          <label>
                            The added balance is equivalent to {addedBalance * 1000}{" "}
                            seconds, or ≈{((addedBalance * 1000) / 60).toFixed(2)}{" "}
                            minutes, or ≈{((addedBalance * 1000) / 3600).toFixed(2)} hours
                            of runtime
                          </label>
                          <br />
                          <button onClick={() => handleCheckout()}>Checkout</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function BillingPage() {
  return (
    <>
      <NavigationBar />
      <Billing />
    </>
  );
}
