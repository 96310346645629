/**
 * Utils: Back-end
 */

import config from "../config";

/**
 * Register a new user
 */
export const userRegister = async (email, password) => {
  return await requestApi(
    "/auth/register",
    "POST",
    { username: email, password: password },
    {}
  );
};

/**
 * Login a new user
 */
export const userLogin = async (email, password) => {
  return await requestApi(
    "/auth/login",
    "POST",
    { username: email, password: password },
    {}
  );
};

/**
 * Activate a new account
 */
export const activateAccount = async () => {
  return await requestApi("/auth/confirm_email", "GET");
};

/**
 * Log out the user
 */

export const deleteSession = async () => {
  return await requestApi("/auth/logout", "DELETE");
};

/**
 * Reset Password
 */
export const userRequestPasswordReset = async (email) => {
  return await requestApi("/reset/request", "POST", { email: email });
};

export const userUpdatePassword = async (token, id, newPassword) => {
  return await requestApi("/reset/update-password", "POST", {
    newPassword: newPassword,
    token: token,
    id: id,
  });
};

export const getUser = async () => {
  return await requestApi("/user/userinfo", "GET");
};

export const resendVerificationEmail = async () => {
  return await requestApi("/auth/resend-verification", "GET");
};

export const createCheckoutLink = async (addedBalance) => {
  return await requestApi("/stripe/create-checkout-session", "POST", {
    addedBalance: addedBalance,
  });
};

export const hasRepositoryToken = async () => {
  const response = await requestApi("/repository/hastoken", "GET");
  console.log(response);

  return response.data.hasToken;
};

export const saveGitHubToken = async (accessToken, refreshToken) => {
  await requestApi("/auth/githubapp/savetoken", "POST", {
    accessToken: accessToken,
    refreshToken: refreshToken,
  });
};

export const saveBitBucketToken = async (accessToken, refreshToken) => {
  await requestApi("/auth/bitbucketapp/savetoken", "POST", {
    accessToken: accessToken,
    refreshToken: refreshToken,
  });
};

/**
 * API request to call the backend
 */
export const requestApi = async (
  path = "",
  method = "GET",
  data = null,
  headers = {}
) => {
  // Check if API URL has been set
  if (!config?.domains?.api) {
    throw new Error(
      `Error: Missing API Domain – Please add the API domain from your serverless Express.js back-end to this front-end application.  You can do this in the "site" folder, in the "./config.js" file.  Instructions are listed there and in the documentation.`
    );
  }

  // Prepare URL
  if (!path.startsWith("/")) {
    path = `/${path}`;
  }
  const url = `${config.domains.api}${path}`;

  // Set headers
  headers = Object.assign({ "Content-Type": "application/json" }, headers);

  // Default options are marked with *
  const response = await fetch(url, {
    method: method.toUpperCase(),
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: headers,
    body: data ? JSON.stringify(data) : null,
  });

  if (response.status === 500) {
    return await response.text();
  }

  if (response.status < 200 || response.status >= 303) {
    const error = await response.json();
    throw new Error(error.error);
  }

  return await response.json();
};
