import React from 'react'

export default function DashboardWrapper({ children }) {
    return (
    <div class="pcoded-main-container">
        <div class="pcoded-wrapper">
            <div class="pcoded-content">
                <div class="pcoded-inner-content">
                    <div class="main-body">
                            { children }
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
