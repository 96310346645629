import config from "config";
import { useEffect } from "react";
import { hasRepositoryToken } from "utils";
import "../../../styles/fonts/fontawesome/css/fontawesome-all.min.css";

export default function ConnectRepositoryCard() {
  const navigateTo = (link) => {
    window.location.href = link;
  };

  useEffect(async () => {
    await hasRepositoryToken();
  }, []);

  return (
    <>
      {/* GitHub Card */}
      <div className="page-wrapper">
        <div
          className="card max-width-700 card-hover-effect"
          onClick={() => navigateTo(config.installationURLs.github)}
        >
          <div className="card-block">
            <div className="row d-flex align-items-center">
              <div className="col-auto">
                <i className="fab fa-github f-42 text-c-black"></i>
              </div>
              <div className="col" style={{ height: "30px" }}>
                <h4 className="f-w-300">Connect Your GitHub Repositories</h4>
              </div>
            </div>
          </div>
        </div>

        {/* BitBucket Card */}
        <div
          className="card max-width-700 card-hover-effect"
          onClick={() => navigateTo(config.installationURLs.bitbucket)}
        >
          <div className="card-block">
            <div className="row d-flex align-items-center">
              <div className="col-auto">
                <i className="fab fa-bitbucket f-42 text-c-blue"></i>
              </div>
              <div className="col" style={{ height: "30px" }}>
                <h4 className="f-w-300">Connect Your BitBucket Repositories</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
