import React, {useState, useEffect} from 'react';

import { ReactComponent as SuccessIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as FailureIcon } from "feather-icons/dist/icons/x-circle.svg";

import { useSearchParams } from 'react-router-dom';

import "./../../styles/Dashboard/payment.css";

export default function PaymentStatusPopover() {
    let [searchParams, setSearchParams] = useSearchParams();

    const removeQueryParams = () => {
        setSearchParams({});
        document.getElementsByClassName("popover-overlay")[0].style.display = "none";
    }

    return (
        <>
            {searchParams.get("success") === "true" ? <PaymentSuccess addedBalance={searchParams.get("amount")} removeQueryParams={removeQueryParams} /> : null}
            {searchParams.get("success") === "false" ? <PaymentFailure removeQueryParams={removeQueryParams} /> : null}
        </>
    )
}



function PaymentSuccess(props) {
    return (
        <div className="popover-overlay">
            <div className="popover-box">
                <SuccessIcon 
                    stroke="#00c04b"
                    height="80"
                    width="80"
                />
                <h4>Payment Successful! <span style={{fontWeight: "bolder"}}>{props.addedBalance}$</span> has been topped up to your account.</h4>
                <button onClick={() => props.removeQueryParams()}>Close</button>
            </div>
        </div>
    )
}

function PaymentFailure(props) {
    return (
        <div className="popover-overlay">
            <div className="popover-box">
                <FailureIcon
                    stroke="#ff4d4d"
                    height="80"
                    width="80"
                />
                <h4>Payment Failed! Please try again.</h4>
                <button onClick={() => props.removeQueryParams()}>Close</button>
            </div>
        </div>
    )
}
