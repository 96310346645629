import ConfirmationBox from 'components/Dashboard/ConfirmationBox';

import { faXmarkCircle } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useSearchParams } from 'react-router-dom';

import "../../styles/Dashboard/confirmation-box.css"

export default function InvalidLink() {
    let [searchParams, setSearchParams] = useSearchParams();

    const type = searchParams.get("type");
    
    const title = "Invalid Link"
    const buttonText = "Go to Login"
    const passwordResetMessage = "Please make a new password reset request and try again.";
    const verifyEmailMessage = "Please login to your account and recieve a new account activation link.";
    const message = "The URL is either invalid or expired. " 

    return (
        <ConfirmationBox
          title={title}
          message={type === "password" ? message + passwordResetMessage : message + verifyEmailMessage}
          hasButton={true}
          buttonText={buttonText}
          buttonPath={"/login"}
          hasSignOut={false}
          logo="xmark"
          icon={<FontAwesomeIcon icon={faXmarkCircle} size="10x" style={{color: "#ff4d4d"}}/>}
        />
    )
}
