import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { deleteSession } from "utils";

import { ReactComponent as SilverlinigLogo } from "./../../assets/silverlining_logo.svg";
import { ReactComponent as DatabaseIcon } from "./../../assets/database.svg";

import Loader from "./Loader";

export default function NavigationBar() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/app/dashboard") {
      document.getElementsByClassName("nav-item")[1].classList.add("active");
    } else if (location.pathname === "/app/repositories") {
      document.getElementsByClassName("nav-item")[2].classList.add("active");
    } else {
      document.getElementsByClassName("nav-item")[3].classList.add("active");
    }

    window.onresize = monitorNavBar;
  }, []);

  const updatePage = (e) => {
    e.preventDefault();

    let element = e.target;

    if (!element.href) {
      element = element.parentElement;
    }
    if (!element.href) {
      element = element.parentElement;
    }
    if (!element.href) {
      element = element.parentElement;
    }

    if (element.href && !element.href.includes(location.pathname)) {
      window.location.href = element.href;
    }
  };

  const toggleNavBar = () => {
    if (window.innerWidth <= 992) {
      document.getElementsByClassName("pcoded-navbar")[0].classList.toggle("mob-open");
    } else {
      document
        .getElementsByClassName("pcoded-navbar")[0]
        .classList.toggle("navbar-collapsed");
      document.getElementsByClassName("mobile-menu")[0].classList.toggle("on");
    }
  };

  const monitorNavBar = () => {
    if (window.innerWidth <= 922) {
      document
        .getElementsByClassName("pcoded-navbar")[0]
        .classList.remove("navbar-collapsed");
    }
  };

  const logOut = async () => {
    // TODO: Check our the issue regarding log out
    await deleteSession();
    window.location.href = "/";
  };

  return (
    <>
      <Loader />

      {/* Desktop NavBar */}
      <nav className="pcoded-navbar">
        <div className="navbar-wrapper">
          <div className="navbar-brand header-logo">
            <a href="/dashboard" className="b-brand">
              <div className="b-bg">
                <SilverlinigLogo />
              </div>
              <span className="b-title">SilverLining.Cloud</span>
            </a>
            <a
              className="mobile-menu"
              id="mobile-collapse"
              href="javascript:"
              onClick={toggleNavBar}
            >
              <span></span>
            </a>
          </div>
          <div
            className="slimScrollDiv"
            style={{
              position: "relative",
              overflow: "hidden",
              width: "100%",
              height: "calc(100% - 70px)",
            }}
          >
            <div className="navbar-content scroll-div">
              <ul className="nav pcoded-inner-navbar">
                <li className="nav-item pcoded-menu-caption">
                  <label>Navigation</label>
                </li>
                <li
                  data-username="dashboard Default Ecommerce CRM Analytics Crypto Project"
                  className="nav-item"
                >
                  <a
                    href="/app/dashboard"
                    className="nav-link"
                    onClick={(e) => updatePage(e)}
                  >
                    <span className="pcoded-micon">
                      <i className="feather icon-home"></i>
                    </span>
                    <span className="pcoded-mtext">Dashboard</span>
                  </a>
                </li>
                <li
                  data-username="dashboard Default Ecommerce CRM Analytics Crypto Project"
                  className="nav-item"
                >
                  <a
                    href="/app/repositories"
                    className="nav-link"
                    onClick={(e) => updatePage(e)}
                  >
                    <span className="pcoded-micon">
                      <i className="feather">
                        <DatabaseIcon />
                      </i>
                    </span>
                    <span className="pcoded-mtext">Repositories</span>
                  </a>
                </li>
                <li
                  data-username="dashboard Default Ecommerce CRM Analytics Crypto Project"
                  className="nav-item"
                >
                  <a
                    href="/app/billing"
                    className="nav-link"
                    onClick={(e) => updatePage(e)}
                  >
                    <span className="pcoded-micon">
                      <i className="feather icon-shopping-cart"></i>
                    </span>
                    <span className="pcoded-mtext">Billing</span>
                  </a>
                </li>
                <li data-username="Disabled Menu" className="nav-item" onClick={logOut}>
                  <a href="javascript:" className="nav-link">
                    <span className="pcoded-micon">
                      <i className="feather icon-power"></i>
                    </span>
                    <span class="pcoded-mtext">Log Out</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile NavBar */}
      <header className="navbar pcoded-header navbar-expand-lg navbar-light">
        <div className="m-header">
          <a
            className="mobile-menu"
            id="mobile-collapse1"
            href="javascript:"
            onClick={toggleNavBar}
          >
            <span></span>
          </a>
          <a href="/app/dashboard" className="b-brand">
            <div className="b-bg">
              <SilverlinigLogo />
            </div>
            <span className="b-title">SilverLining.Cloud</span>
          </a>
        </div>
        <a className="mobile-menu" id="mobile-header" href="javascript:">
          <i className="feather icon-more-horizontal"></i>
        </a>
      </header>

      <Outlet />
    </>
  );
}
