import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ProtectedRoute from "components/Dashboard/ProtectedRoute";
import NavigationBar from "components/Dashboard/NavigationBar";

import Home from "./pages/Home/Home";
import SignUp from "pages/Auth/SignUp";
import Login from "pages/Auth/Login";

import Dashboard from "./pages/Dashboard/Dashboard";
import BillingPage from "./pages/Dashboard/BillingPage";
import ConfirmEmail from "pages/Dashboard/ConfirmEmail";
import AccountActivated from "pages/Dashboard/AccountActivated";
import ResetPassword from "pages/Auth/ResetPassword";
import RequestPasswordReset from "./pages/Auth/RequestPasswordReset";
import InvalidLink from "pages/Dashboard/InvalidLink";
import Repositories from "pages/Dashboard/Repositories";
import AuthProvider from "contexts/AuthenticationContext";
import TokenSave from "pages/Dashboard/TokenSave";

export default function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/invalid-link" element={<InvalidLink />} />
          <Route path="/request-password-reset" element={<RequestPasswordReset />} />

          {/* TODO: Fixing the protected route minor issues and final handling */}

          {/* Private Routes */}
          <Route
            path="/confirm-email"
            element={<ProtectedRoute component={<ConfirmEmail />} />}
          />
          <Route
            path="/account-activated"
            element={<ProtectedRoute component={<AccountActivated />} />}
          />

          <Route path="/token" element={<ProtectedRoute component={<TokenSave />} />} />

          <Route path="/app" element={<ProtectedRoute component={<NavigationBar />} />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="billing" element={<BillingPage />} />
            <Route path="repositories" element={<Repositories />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}
