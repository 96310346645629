import DashboardWrapper from "components/Dashboard/DashboardWrapper";
import ConnectRepositoryCard from "components/Dashboard/Repositories/ConnectRepositoryCard";
import RepositoryTable from "components/Dashboard/Repositories/RepositoryTable";
import useFetch from "hooks/useFetch";

import "../../styles/Dashboard/repositories.css";

export default function Repositories() {
  const { data, error, loading } = useFetch(
    "https://automate.silverlining.cloud/api/repository/hastoken"
  );

  if (loading) {
    return <div>Loading</div>;
  }

  if (data && data.hasToken === false) {
    return (
      <DashboardWrapper>
        <ConnectRepositoryCard />
      </DashboardWrapper>
    );
  }

  if (data && data.hasToken === true) {
    return (
      <DashboardWrapper>
        <RepositoryTable />
      </DashboardWrapper>
    );
  }
}
